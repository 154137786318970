import React from 'react'
import PropertyForm from './PropertyForm';

function Sell() {
  return (
    <main>
      <PropertyForm />
    </main>
  )
}


export default Sell;