import React from 'react';
import PropertyList from './PropertyList';

function SavedProperty() {
  return (
    <div>
      <h1>Saved Property</h1>
      <PropertyList />
    </div>
  )
}


export default SavedProperty;