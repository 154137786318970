import React from 'react'

function Contact_Us() {
  return (
    <div>
      <h1>Contact us</h1>
    </div>
  )
}

export default Contact_Us;
